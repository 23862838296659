.MuiSlider-root {
  padding: 3px 0 !important;
}
.MuiSlider-thumb {
  color: #c84027;
}
.MuiSlider-rail {
  opacity: 0.2;
  color: #c84027;
}
.MuiSlider-track {
  color: #c84027;
}
.MuiSlider-valueLabel {
  font-family: Lato !important;
  color: #c84027 !important;
  background: transparent !important;
  top: -6px !important;
  font-weight: bold;
}
