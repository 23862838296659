/* .products-home {
  grid-template-columns: repeat(auto-fill, 100px);
} */
.swiper {
  min-width: 100% !important;
}
.swiper-button-next, .swiper-button-prev{
  color:#e1644e !important ;
}
@media only screen and (max-width: 950px) {
  .swiper-button-next, .swiper-button-prev{
    display: none !important;
  }
}