.qty .easy-edit-wrapper {
  font-weight: 600;
  font-size: large;
  color: #c84027;
  font-family: "Lato";
  position: absolute;
    bottom: 20px;
    right: 20px
  /* margin-right: 20px; */
}

.qty .easy-edit-inline-wrapper {
  width: 100%;
  /* position: absolute; */
  background-color: #F5F8FB;
  padding: 10px;
  /* left: 0; */
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.qty .easy-edit-component-wrapper {
  display: inline-grid;
  width: 50%;
  margin-right: 8px;
}

input[type="text"] {
  overflow: hidden;
}
.MuiButtonBase-root.MuiChip-root .MuiChip-deleteIcon{
  margin-left: 5px;
}
.MuiSpeedDial-fab{
  /* box-shadow: none !important; */
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1) !important;
}
.MuiSpeedDial-fab:hover{
  background-color: #ae2012 !important;
}
.MuiSpeedDialAction-fab{
  box-shadow: none !important;
  background-color: #F5F8FB !important;
}
.MuiSpeedDialAction-fab:hover{
  
  background-color: #dee2e6 !important;
}