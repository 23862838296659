@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Lato", "sans-serif";
}

[type="number"]:focus {
  outline: 0;
  outline-width: 0;
  box-shadow: none;
}
[type="text"]:focus {
  outline: 0;
  outline-width: 0;
  box-shadow: none;
}
.daily-swiper.swiper {
  /* height: auto !important; */
}
.swiper {
  /* max-height: 850px; */
  /* height: 850px !important; */
  height: auto;
  /* width: 100%; */

  /* margin-left: auto; */
  /* margin-right: auto; */
}
.mySwiper2 .swiper-slide {
  opacity: 0 !important;
}
.mySwiper2 .swiper-slide.swiper-slide-active {
  opacity: 1 !important;
}
.mySwiper2 .swiper-slide.swiper-slide-next {
  opacity: 0 !important;
}
.mySwiper2 .swiper-slide.swiper-slide-prev {
  opacity: 0 !important;
}
.swiper-slide.swiper-slide-prev {
  /* opacity: 1 !important;
    transform: translate3d(0px, 0px, 0px) !important;
    transition-duration: 0ms */
}
/* .swiper-slide.swiper-slide-visible.swiper-slide-active{
    opacity: 1 !important;
    transform: translate3d(0px, 0px, 0px) !important;
    transition-duration: 0ms
  } */
.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  /* height: 850px; */
  /* max-height: 850px; */
  height: auto !important;
  width: 100%;
  align-items: flex-start !important;
  /* transition-property: transform,height !important; */
  transform: translate3d(0px, 0px, 0px) !important;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.mySwiper2 .swiper-slide {
  /* width: 25%; */
  height: auto !important;
  /* opacity: 0.6; */
}
.mySwiper .swiper-slide {
  /* width: 25%; */
  height: auto;
  opacity: 0.6;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
}

.swiper-wrapper {
  /* height: auto !important; */
  align-items: flex-start !important;
  transition-property: transform, height !important;
  transform: translate3d(0px, 0, 0);
}
