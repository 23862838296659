.MuiPaper-root.MuiTableContainer-root{
    box-shadow: none;
}
.easy-edit-wrapper{
    position: relative;
    right: 0;
    bottom: 0;
    color: black;
    text-transform: uppercase;
    font-size: medium;
}