.search {
  background: white url("../assets/SVG/search.svg") left no-repeat;
  background-position-x: 10px;
  text-indent: 35px;
}
.visuallyHidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.hamburger {
  width: 20px;
  height: 21px;
  position: relative;
}

.hamburger .bar {
  padding: 0;
  width: 20px;
  height: 2px;
  background-color: rgb(200 64 39);
  display: block;
  border-radius: 4px;
  /* transition: all 0.2s ease-in-out; */
  position: absolute;
}
.bar1 {
  top: 1px;
}

.bar2,
.bar3 {
  top: 10px;
}

.bar3 {
  right: 0;
}

.bar4 {
  top: 19px;
}

.MuiBadge-colorPrimary {
  background-color: #c84027 !important;
}

.mobileSearch {
  background: rgb(241 245 249) url("../assets/SVG/search.svg") right no-repeat;
  background-position: 280px;
  /* background-position-x: -10px; */
  /* text-indent: 35px; */
}
