.item {
  /* background-image: url("../assets/PNG/woman.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  background-position-x: center;
}
.MuiPagination-ul {
  justify-content: center;
}
.MuiPagination-ul li {
  margin-bottom: 10px;
}
/* .MuiDrawer-paper {
  width: 100% !important;
} */
.MuiAccordion-root {
  /* background-color: #f5f8fb !important; */
  box-shadow: none !important;
  border: none !important;
}
.MuiAccordionSummary-root {
  border: none !important;
  border-radius: 5px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.MuiPaper-root .MuiAccordion-root:before {
  height: 0 !important;
}
.MuiAccordionDetails-root {
  padding: 25px !important;
  padding-top: 0 !important;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 10px 0 !important;
  /* margin-top: 10px !important; */
}
.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  min-height: 50px !important;
}
.MuiButtonBase-root.MuiAccordionSummary-root {
  border-radius: 5px !important;
}
.MuiPaper-root.MuiAccordion-root {
  border-radius: 5px !important;
}
.MuiFab-root:hover {
  background-color: #075e54 !important;
}
